function colorSwap(e) {
    var menu = document.getElementById('menu');
    var burgers = document.querySelectorAll('input+label span');
    if (e.checked) {
        menu.style.color = "white";
        burgers.forEach(e => {
            e.style.backgroundColor = "white";
        });
    } else {
        menu.style.color = "#1a4a72";
        burgers.forEach(e => {
            e.style.backgroundColor = "#1a4a72";
        });
    }
}

window.colorSwap = colorSwap;